import { lazy, useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const PersonToday = lazy(() => import('./jothidam/PersonToday'));

function Home() {

    const [ isLanding, setIsLanding ] = useState(true);

    const navigate = useNavigate();
    const routeLocation = useLocation();


    useEffect(() => {

        // console.log('**************** ' + routeLocation.pathname);

        setIsLanding(false);

        if (routeLocation.pathname === '/') {
            setIsLanding(true);
        }

        if (window.location.search.includes('app')) {
            navigate('/jd/profile');
        }

    }, [ routeLocation ]);

    return (
        <div>
            <nav>
                <ul style={ { listStyle: 'none', display: 'flex' } }>
                    <li style={ { marginRight: 20 } }>
                        <Link to="/jd/profile">Jadhakam</Link>
                    </li>
                    <li style={ { marginRight: 20 } }>
                        <Link to="/planets">Planets</Link>
                    </li>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                </ul>
            </nav>

            { isLanding && (
                <PersonToday />
            ) }

            <Outlet />
        </div>
    );
}

export default Home;