import axios from 'axios';
import { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';

import './App.css';

let isAppEnabled = false;

if (window.location.origin.includes('localhost')) {
  isAppEnabled = true;
  axios.defaults.baseURL = 'http://127.0.0.1:6002';
} else if (window.location.search.includes('surya') || window.location.search.includes('siva')) {
  if (window.location.search.includes('app')) {
    isAppEnabled = true;
  }

}

const PlanetMovement = lazy(() => import('./planets/PlanetMovement'));

const JadhakamMain = lazy(() => import('./jothidam/JadhakamMain'));
const Profile = lazy(() => import('./jothidam/Profile'));
const Locations = lazy(() => import('./jothidam/Locations'));
const PersonJadhakam = lazy(() => import('./jothidam/PersonJadhakam'));

const PersonToday = lazy(() => import('./jothidam/PersonToday'));
const PersonDivisional = lazy(() => import('./jothidam/PersonDivisional'));
const Incident = lazy(() => import('./jothidam/Incident'));

// Error Notification Component
const ErrorNotification = ({ message }: { message: string; }) => (
  <div className="error-notification">{ message }</div>
);

function App() {
  const [ errorMessage, setErrorMessage ] = useState<string | null>(null);

  useEffect(() => {
    if (!isAppEnabled) {
      setErrorMessage('Access Restricted');
    } else {

      const reqInterctor = axios.interceptors.request.use(
        (config) => {
          setErrorMessage('Loading data...');
          return config; // Always return the config
        },
        (error) => {
          return Promise.reject(error); // Handle errors
        }
      );

      const interceptor = axios.interceptors.response.use(
        response => {

          if (response.data && response.data.Error) {
            setErrorMessage(response.data.Error);
          } else if (response.data && response.data.Status) {
            setErrorMessage(response.data.Status);
          } else {
            setErrorMessage(null);
          }

          return response;
        },
        error => {
          // Clear loading message if an error occurs
          setErrorMessage(null); // Clear out loading message

          if (error.response && error.response.data && error.response.data.Error) {
            setErrorMessage(error.response.data.Error);
          }
          return Promise.reject(error);
        }
      );

      // Cleanup interceptor on unmount
      return () => {
        axios.interceptors.request.eject(reqInterctor);
        axios.interceptors.response.eject(interceptor);
      };
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={ <div>Loading...</div> }>
        { errorMessage && <ErrorNotification message={ errorMessage } /> }
        <Routes>
          <Route path="/" element={ <Home /> }>
            { isAppEnabled && (
              <Route path="jd" element={ <JadhakamMain /> }>
                <Route path="profile" element={ <Profile /> } />
                <Route path="locations" element={ <Locations /> } />
                <Route path="person-jd/:profileId" element={ <PersonJadhakam /> } />
                <Route path="today/:profileId" element={ <PersonToday /> } />
                <Route path="incident/:profileId" element={ <Incident /> } />
                <Route path="divisional/:profileId" element={ <PersonDivisional /> } />
              </Route>
            ) }
            <Route path="planets" element={ <PlanetMovement /> } />

          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
